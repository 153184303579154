<template>
    <div class="promo-page">
        <section class="connect-banner">
            <div class="container">
                <div class="connect-banner__wrapp">
                    <div class="connect-banner__text">
                        <div class="logo"><img src="/images/connect/logo.png" alt="logo"></div>
                            <h1>ukasko.connect — сучасна платформа страхування</h1>
                        <div class="descr">
                        Готові рішення для вашого бізнесу в сфері страхування
                        </div>
                        <router-link class="btn btn-blue to-form" :to="{ name: 'registration'}">
                            Cпробувати безкоштовно
                        </router-link>
                    </div>
                    <div class="connect-banner__preview"><img src="/images/connect/banner.png"></div>
                </div>
            </div>
        </section>

        <section class="connect-whoIs">
            <div class="container">
                <h2>Для кого підійде?</h2>
                <div id="partnersTab" role="tablist" class="nav nav-tabs"><a id="offline-tab" data-toggle="tab" href="#offline" role="tab" aria-controls="offline" aria-selected="true" class="nav-item nav-link active">Офлайн </a> <a id="online-tab" data-toggle="tab" href="#online" role="tab" aria-controls="online" aria-selected="false" class="nav-item nav-link">Онлайн </a></div>
                <div class="tab-content">
                    <div id="offline" role="tabpanel" aria-labelledby="offline-tab" class="tab-pane fade active show">
                        <div class="connect-whoIs__slider__container slider-offline">
                        <div class="whoIs__slider swiper-container">
                            <div class="whoIs__slider__list swiper-wrapper">
                                <div class="connect-whoIs__item swiper-slide">
                                    <div class="preview"><img src="/images/connect/whois2.svg"></div>
                                    <p>Станції ОТК</p>
                                </div>
                                <div class="connect-whoIs__item swiper-slide">
                                    <div class="preview"><img src="/images/connect/whois1.svg"></div>
                                    <p>Мережі АЗС</p>
                                </div>
                                <div class="connect-whoIs__item swiper-slide">
                                    <div class="preview"><img src="/images/connect/whois4.svg"></div>
                                    <p>Автосалони</p>
                                </div>
                                <div class="connect-whoIs__item swiper-slide">
                                    <div class="preview"><img src="/images/connect/whois3.svg"></div>
                                    <p>Страхові агенти та брокери</p>
                                </div>
                            </div>
                            <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                        </div>
                        <div class="whoIs__slider__pagination"></div>
                        <div class="whoIs__slider__nav">
                            <button class="whoIs__slider__btn prev" role="button" aria-label="Previous slide" aria-disabled="true">
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 20.559L10.6101 22L9.61651e-07 11L10.6101 -9.27563e-07L12 1.441L2.77454 11.0055L12 20.559Z" fill="black"></path>
                                </svg>
                            </button>
                            <button class="whoIs__slider__btn next" role="button" aria-label="Next slide" aria-disabled="true">
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M-1.79732e-06 20.559L1.38992 22L12 11L1.38992 -9.27563e-07L-1.25976e-07 1.441L9.22546 11.0055L-1.79732e-06 20.559Z" fill="black"></path>
                                </svg>
                            </button>
                        </div>
                        </div>
                        <div class="connect-whoIs_other">
                        і багато інших...
                        </div>
                    </div>
                    <div id="online" role="tabpanel" aria-labelledby="online-tab" class="tab-pane fade">
                        <div class="connect-whoIs__slider__container slider-online">
                        <div class="whoIs__slider swiper-container">
                            <div class="whoIs__slider__list swiper-wrapper">
                                <div class="connect-whoIs__item swiper-slide">
                                    <div class="preview"><img src="/images/connect/whois5.svg"></div>
                                    <p>Web-ресурси</p>
                                </div>
                                <div class="connect-whoIs__item swiper-slide">
                                    <div class="preview"><img src="/images/connect/whois6.svg"></div>
                                    <p>Мобільні додатки</p>
                                </div>
                                <div class="connect-whoIs__item swiper-slide">
                                    <div class="preview"><img src="/images/connect/whois3.svg"></div>
                                    <p>Веб-мастера</p>
                                </div>
                                <div class="connect-whoIs__item swiper-slide">
                                    <div class="preview"><img src="/images/connect/whois8.svg"></div>
                                    <p>Telegram-канали</p>
                                </div>
                            </div>
                            <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                        </div>
                        <div class="whoIs__slider__pagination"></div>
                        <div class="whoIs__slider__nav">
                            <button class="whoIs__slider__btn prev" role="button" aria-label="Previous slide" aria-disabled="true">
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 20.559L10.6101 22L9.61651e-07 11L10.6101 -9.27563e-07L12 1.441L2.77454 11.0055L12 20.559Z" fill="black"></path>
                                </svg>
                            </button>
                            <button class="whoIs__slider__btn next" role="button" aria-label="Next slide" aria-disabled="true">
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M-1.79732e-06 20.559L1.38992 22L12 11L1.38992 -9.27563e-07L-1.25976e-07 1.441L9.22546 11.0055L-1.79732e-06 20.559Z" fill="black"></path>
                                </svg>
                            </button>
                        </div>
                        </div>
                        <div class="connect-whoIs_other">
                        і багато інших...
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="connect-variants">
            <div class="container">
                <h2>Варіанти готових рішень для вашого бізнесу</h2>
                <div role="tablist" class="connect-variants__nav nav">
                    <a data-toggle="tab" href="#variant1" role="tab" aria-controls="variant1" aria-selected="false" class="item active">
                        <div class="icon">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36.4844 0H3.51562C1.57711 0 0 1.57711 0 3.51562V36.4844C0 38.4229 1.57711 40 3.51562 40H36.4844C38.4229 40 40 38.4229 40 36.4844V3.51562C40 1.57711 38.4229 0 36.4844 0ZM18.75 37.6562H3.51562C2.86945 37.6562 2.34375 37.1305 2.34375 36.4844V21.1719H18.75V37.6562ZM18.75 18.8281H2.34375V3.51562C2.34375 2.86945 2.86945 2.34375 3.51562 2.34375H18.75V18.8281ZM37.6562 36.4844C37.6562 37.1305 37.1305 37.6562 36.4844 37.6562H21.0938V21.1719H37.6562V36.4844ZM37.6562 18.8281H21.0938V2.34375H36.4844C37.1305 2.34375 37.6562 2.86945 37.6562 3.51562V18.8281Z" fill="#C7D1D7"></path>
                            <path d="M16.0631 33.3116L12.2042 29.4527L16.063 25.5938C16.5207 25.1362 16.5207 24.3942 16.063 23.9366C15.6054 23.4789 14.8634 23.4789 14.4057 23.9366L10.5469 27.7954L6.68801 23.9365C6.23043 23.4788 5.4884 23.4788 5.03074 23.9365C4.57309 24.3942 4.57309 25.1361 5.03074 25.5938L8.88965 29.4527L5.03082 33.3115C4.57316 33.7692 4.57316 34.5111 5.03082 34.9688C5.4884 35.4263 6.23043 35.4264 6.68809 34.9688L10.5469 31.1099L14.4058 34.9688C14.8634 35.4264 15.6054 35.4265 16.0631 34.9688C16.5207 34.5112 16.5207 33.7692 16.0631 33.3116Z" fill="#C7D1D7"></path>
                            <path d="M15.2344 9.375H11.7188V5.85938C11.7188 5.21219 11.1941 4.6875 10.5469 4.6875C9.89969 4.6875 9.375 5.21219 9.375 5.85938V9.375H5.85938C5.21219 9.375 4.6875 9.89969 4.6875 10.5469C4.6875 11.1941 5.21219 11.7188 5.85938 11.7188H9.375V15.2344C9.375 15.8816 9.89969 16.4062 10.5469 16.4062C11.1941 16.4062 11.7188 15.8816 11.7188 15.2344V11.7188H15.2344C15.8816 11.7188 16.4062 11.1941 16.4062 10.5469C16.4062 9.89969 15.8816 9.375 15.2344 9.375Z" fill="#C7D1D7"></path>
                            <path d="M34.1406 9.375H24.7656C24.1184 9.375 23.5938 9.89969 23.5938 10.5469C23.5938 11.1941 24.1184 11.7188 24.7656 11.7188H34.1406C34.7878 11.7188 35.3125 11.1941 35.3125 10.5469C35.3125 9.89969 34.7878 9.375 34.1406 9.375Z" fill="#C7D1D7"></path>
                            <path d="M34.1406 25.9375H24.7656C24.1184 25.9375 23.5938 26.4622 23.5938 27.1094C23.5938 27.7566 24.1184 28.2812 24.7656 28.2812H34.1406C34.7878 28.2812 35.3125 27.7566 35.3125 27.1094C35.3125 26.4622 34.7878 25.9375 34.1406 25.9375Z" fill="#C7D1D7"></path>
                            <path d="M34.1406 30.625H24.7656C24.1184 30.625 23.5938 31.1497 23.5938 31.7969C23.5938 32.4441 24.1184 32.9688 24.7656 32.9688H34.1406C34.7878 32.9688 35.3125 32.4441 35.3125 31.7969C35.3125 31.1497 34.7878 30.625 34.1406 30.625Z" fill="#C7D1D7"></path>
                        </svg>
                        </div>
                        <p>Калькулятор ОСЦПВ на вашому сайті</p>
                    </a>
                    <a data-toggle="tab" href="#variant2" role="tab" aria-controls="variant2" aria-selected="false" class="item">
                        <div class="icon">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path d="M26.25 38.3327C25.56 38.3327 25 37.7727 25 37.0827V9.16606C25 8.47606 25.56 7.91606 26.25 7.91606C26.94 7.91606 27.5 8.47606 27.5 9.16606V37.0827C27.5 37.7727 26.94 38.3327 26.25 38.3327Z" fill="#C7D1D7"></path>
                                <path d="M20.4167 24.1661H6.25C5.56 24.1661 5 23.6061 5 22.9161C5 22.2261 5.56 21.6661 6.25 21.6661H20.4167C21.1067 21.6661 21.6667 22.2261 21.6667 22.9161C21.6667 23.6061 21.1067 24.1661 20.4167 24.1661Z" fill="#C7D1D7"></path>
                                <path d="M20.4167 16.6661H6.25C5.56 16.6661 5 16.1061 5 15.4161C5 14.7261 5.56 14.1661 6.25 14.1661H20.4167C21.1067 14.1661 21.6667 14.7261 21.6667 15.4161C21.6667 16.1061 21.1067 16.6661 20.4167 16.6661Z" fill="#C7D1D7"></path>
                                <path d="M20.4167 31.6661H6.25C5.56 31.6661 5 31.1061 5 30.4161C5 29.7261 5.56 29.1661 6.25 29.1661H20.4167C21.1067 29.1661 21.6667 29.7261 21.6667 30.4161C21.6667 31.1061 21.1067 31.6661 20.4167 31.6661Z" fill="#C7D1D7"></path>
                                <path d="M32.5 20C31.1217 20 30 18.8783 30 17.5C30 16.1217 31.1217 15 32.5 15C33.8783 15 35 16.1217 35 17.5C35 18.8783 33.8783 20 32.5 20ZM32.5 17.5H31.25L32.5 17.5017C32.5 17.5 32.5 17.5 32.5 17.5Z" fill="#C7D1D7"></path>
                                <path d="M32.5 31.6661C31.1217 31.6661 30 30.5444 30 29.1661C30 27.7877 31.1217 26.6661 32.5 26.6661C33.8783 26.6661 35 27.7877 35 29.1661C35 30.5444 33.8783 31.6661 32.5 31.6661ZM32.5 29.1661H31.25L32.5 29.1677C32.5 29.1661 32.5 29.1661 32.5 29.1661Z" fill="#C7D1D7"></path>
                                <path d="M35.4167 38.3327H4.58333C2.055 38.3327 0 36.2777 0 33.7494V6.24939C0 3.72106 2.055 1.66606 4.58333 1.66606H35.4167C37.945 1.66606 40 3.72106 40 6.24939V33.7494C40 36.2777 37.945 38.3327 35.4167 38.3327ZM4.58333 4.16606C3.435 4.16606 2.5 5.10106 2.5 6.24939V33.7494C2.5 34.8977 3.435 35.8327 4.58333 35.8327H35.4167C36.565 35.8327 37.5 34.8977 37.5 33.7494V6.24939C37.5 5.10106 36.565 4.16606 35.4167 4.16606H4.58333Z" fill="#C7D1D7"></path>
                                <path d="M38.75 9.99999H1.25C0.56 9.99999 0 9.43999 0 8.74999C0 8.05999 0.56 7.49999 1.25 7.49999H38.75C39.44 7.49999 40 8.05999 40 8.74999C40 9.43999 39.44 9.99999 38.75 9.99999Z" fill="#C7D1D7"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="40" height="40" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                        </div>
                        <p>Брендований лендінг для вашого сайту</p>
                    </a>
                    <a data-toggle="tab" href="#variant3" role="tab" aria-controls="variant3" aria-selected="false" class="item">
                        <div class="icon">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.8375 20C37.8375 18.4586 37.641 16.9338 37.2528 15.4561L39.5106 13.7951L35.1283 6.20484L32.557 7.33141C30.3587 5.14875 27.6668 3.59336 24.6907 2.78609L24.3812 0H15.6167L15.3071 2.78609C12.3309 3.59336 9.6391 5.14875 7.44082 7.33141L4.86957 6.20484L0.487305 13.7951L2.74512 15.4561C2.35684 16.9338 2.16043 18.4586 2.16043 20C2.16043 21.5414 2.35691 23.0662 2.74512 24.5439L0.487305 26.2048L4.86957 33.7951L7.44082 32.6685C9.63918 34.8512 12.331 36.4066 15.3071 37.2138L15.6167 40H24.3812L24.6907 37.2138C27.667 36.4066 30.3588 34.8512 32.5571 32.6685L35.1283 33.7951L39.5106 26.2048L37.2528 24.5439C37.641 23.0662 37.8375 21.5414 37.8375 20ZM23.3631 35.1276L22.5443 35.3088L22.2834 37.6562H17.7145L17.4536 35.3088L16.6348 35.1276C13.5302 34.4405 10.7456 32.8316 8.58215 30.4748L8.01574 29.8578L5.85043 30.8065L3.56598 26.8497L5.46637 25.4517L5.21512 24.6527C4.7434 23.1521 4.50418 21.5867 4.50418 20C4.50418 18.4132 4.7434 16.8478 5.21512 15.3473L5.46637 14.5483L3.56598 13.1503L5.85043 9.19352L8.01574 10.1422L8.58215 9.52516C10.7456 7.16844 13.5302 5.55953 16.6348 4.87242L17.4536 4.69117L17.7145 2.34375H22.2834L22.5443 4.69117L23.3631 4.87242C26.4677 5.55961 29.2523 7.16852 31.4157 9.52516L31.9821 10.1422L34.1475 9.19352L36.4319 13.1503L34.5315 14.5483L34.7828 15.3473C35.2545 16.8479 35.4937 18.4133 35.4937 20C35.4937 21.5867 35.2546 23.1521 34.7828 24.6527L34.5315 25.4517L36.4319 26.8497L34.1475 30.8065L31.9821 29.8578L31.4157 30.4748C29.2523 32.8316 26.4678 34.4405 23.3631 35.1276Z" fill="#C7D1D7"></path>
                            <path d="M15.3473 17.3173L13.6729 15.6774L9.38672 20.0539L13.7633 24.3401L15.4031 22.6655L12.7011 20.0193L15.3473 17.3173Z" fill="#C7D1D7"></path>
                            <path d="M24.6525 17.3173L27.2987 20.0193L24.5967 22.6655L26.2365 24.3401L30.6131 20.0539L26.3269 15.6774L24.6525 17.3173Z" fill="#C7D1D7"></path>
                            <path d="M17.1943 27.6703L20.5275 11.8297L22.8221 12.3125L19.4889 28.1531L17.1943 27.6703Z" fill="#C7D1D7"></path>
                        </svg>
                        </div>
                        <p>Інтеграція через API</p>
                    </a>
                    <a data-toggle="tab" href="#variant4" role="tab" aria-controls="variant4" aria-selected="false" class="item">
                        <div class="icon">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M38.8235 37.6471H29.3333V32.9153C30.3356 33.9551 31.086 34.6346 31.1335 34.6774C31.5812 35.0814 32.262 35.0815 32.7097 34.6774C35.2057 32.4255 40 27.3349 40 23.7647C40 18.2298 34.5421 14.3452 29.3333 16.1115V6.19608C29.3333 5.54635 28.8066 5.01961 28.1569 5.01961H24.6275V1.17647C24.6275 0.526745 24.1007 0 23.451 0H13.4118C12.762 0 12.2353 0.526745 12.2353 1.17647V5.01961H8.70588C8.05616 5.01961 7.52941 5.54635 7.52941 6.19608V17.5686H1.17647C0.526745 17.5686 0 18.0954 0 18.7451V38.8235C0 39.4733 0.526745 40 1.17647 40H38.8235C39.4733 40 40 39.4733 40 38.8235C40 38.1738 39.4733 37.6471 38.8235 37.6471ZM7.52941 22.5882H6.19608C5.54635 22.5882 5.01961 23.115 5.01961 23.7647C5.01961 24.4144 5.54635 24.9412 6.19608 24.9412H7.52941V27.6078H6.19608C5.54635 27.6078 5.01961 28.1346 5.01961 28.7843C5.01961 29.434 5.54635 29.9608 6.19608 29.9608H7.52941V32.6275H6.19608C5.54635 32.6275 5.01961 33.1542 5.01961 33.8039C5.01961 34.4536 5.54635 34.9804 6.19608 34.9804H7.52941V37.6471H2.35294V19.9216H7.52941V22.5882ZM14.5882 2.35294H22.2745V5.01961H14.5882V2.35294ZM26.9804 37.6471H9.88235C9.88235 36.4165 9.88235 8.61043 9.88235 7.37255H26.9804V17.3783C25.0732 18.8573 23.8431 21.17 23.8431 23.7647C23.8431 25.4763 24.8984 27.6575 26.9804 30.2556V37.6471ZM26.1961 23.7647C26.1961 20.6077 28.7645 18.0392 31.9216 18.0392C35.0786 18.0392 37.6471 20.6077 37.6471 23.7647C37.6471 25.919 34.4054 29.7837 31.9216 32.1917C30.1647 30.4884 26.1961 26.1801 26.1961 23.7647Z" fill="#C7D1D7"></path>
                            <path d="M15.6086 10.0392H13.7263C13.0766 10.0392 12.5498 10.566 12.5498 11.2157C12.5498 11.8654 13.0766 12.3922 13.7263 12.3922H15.6086C16.2584 12.3922 16.7851 11.8654 16.7851 11.2157C16.7851 10.566 16.2584 10.0392 15.6086 10.0392Z" fill="#C7D1D7"></path>
                            <path d="M21.2546 12.3922H23.137C23.7867 12.3922 24.3134 11.8654 24.3134 11.2157C24.3134 10.566 23.7867 10.0392 23.137 10.0392H21.2546C20.6049 10.0392 20.0781 10.566 20.0781 11.2157C20.0781 11.8654 20.6049 12.3922 21.2546 12.3922Z" fill="#C7D1D7"></path>
                            <path d="M15.6086 15.0579H13.7263C13.0766 15.0579 12.5498 15.5847 12.5498 16.2344C12.5498 16.8841 13.0766 17.4109 13.7263 17.4109H15.6086C16.2584 17.4109 16.7851 16.8841 16.7851 16.2344C16.7851 15.5847 16.2584 15.0579 15.6086 15.0579Z" fill="#C7D1D7"></path>
                            <path d="M24.3134 16.2344C24.3134 15.5847 23.7867 15.0579 23.137 15.0579H21.2546C20.6049 15.0579 20.0781 15.5847 20.0781 16.2344C20.0781 16.8841 20.6049 17.4109 21.2546 17.4109H23.137C23.7867 17.4109 24.3134 16.8841 24.3134 16.2344Z" fill="#C7D1D7"></path>
                            <path d="M15.6086 20.0785H13.7263C13.0766 20.0785 12.5498 20.6052 12.5498 21.2549C12.5498 21.9047 13.0766 22.4314 13.7263 22.4314H15.6086C16.2584 22.4314 16.7851 21.9047 16.7851 21.2549C16.7851 20.6052 16.2584 20.0785 15.6086 20.0785Z" fill="#C7D1D7"></path>
                            <path d="M15.6086 25.0972H13.7263C13.0766 25.0972 12.5498 25.6239 12.5498 26.2736C12.5498 26.9234 13.0766 27.4501 13.7263 27.4501H15.6086C16.2584 27.4501 16.7851 26.9234 16.7851 26.2736C16.7851 25.6239 16.2584 25.0972 15.6086 25.0972Z" fill="#C7D1D7"></path>
                            <path d="M15.6086 30.1177H13.7263C13.0766 30.1177 12.5498 30.6444 12.5498 31.2941C12.5498 31.9439 13.0766 32.4706 13.7263 32.4706H15.6086C16.2584 32.4706 16.7851 31.9439 16.7851 31.2941C16.7851 30.6444 16.2584 30.1177 15.6086 30.1177Z" fill="#C7D1D7"></path>
                            <path d="M34.98 23.7652C34.98 23.1155 34.4532 22.5887 33.8035 22.5887H30.0388C29.3891 22.5887 28.8623 23.1155 28.8623 23.7652C28.8623 24.4149 29.3891 24.9417 30.0388 24.9417H33.8035C34.4532 24.9417 34.98 24.4149 34.98 23.7652Z" fill="#C7D1D7"></path>
                        </svg>
                        </div>
                        <p>Фронт-офіс для ваших точок продажу</p>
                    </a>
                </div>
                <div class="tab-content connect-variants__wrapp">
                    <div id="variant1" class="tab-pane fade show active">
                        <div class="connect-variants__wrapp">
                        <div class="connect-variants__info">
                            <div class="heading">Калькулятор ОСЦПВ на вашому сайті</div>
                            <ul>
                                <li>Брендований калькулятор в вашому стилі</li>
                                <li>Технічна допомога та супровід при інтеграції</li>
                                <li>Коротке та готове рішення для початку продажів</li>
                            </ul>
                            <router-link class="btn btn-blue to-form" :to="{ name: 'registration'}">
                                Cпробувати безкоштовно
                            </router-link>
                        </div>
                        <div class="connect-variants__slider__container sl1">
                            <div class="variants__slider swiper-container">
                                <div class="variants__slider__list swiper-wrapper">
                                    <div class="variants__slider__item swiper-slide"><img src="/images/connect/variant-slide1.png"></div>
                                    <div class="variants__slider__item swiper-slide"><img src="/images/connect/variant-slide2.png"></div>
                                    <div class="variants__slider__item swiper-slide"><img src="/images/connect/variant-slide3.png"></div>
                                    <div class="variants__slider__item swiper-slide"><img src="/images/connect/variant-slide4.png"></div>
                                    <div class="variants__slider__item swiper-slide"><img src="/images/connect/variant-slide5.png"></div>
                                </div>
                                <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                            </div>
                            <div class="variants__slider__nav">
                                <button class="variants__slider__btn prev" tabindex="0" role="button" aria-label="Previous slide" aria-disabled="true">
                                    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 20.559L10.6101 22L9.61651e-07 11L10.6101 -9.27563e-07L12 1.441L2.77454 11.0055L12 20.559Z" fill="black"></path>
                                    </svg>
                                </button>
                                <button class="variants__slider__btn next" tabindex="0" role="button" aria-label="Next slide" aria-disabled="false">
                                    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M-1.79732e-06 20.559L1.38992 22L12 11L1.38992 -9.27563e-07L-1.25976e-07 1.441L9.22546 11.0055L-1.79732e-06 20.559Z" fill="black"></path>
                                    </svg>
                                </button>
                            </div>
                            <div class="variants__slider__pagination"></div>
                        </div>
                        </div>
                    </div>
                    <div id="variant2" class="tab-pane fade">
                        <div class="connect-variants__wrapp">
                        <div class="connect-variants__info">
                            <div class="heading">Калькулятор ОСЦПВ на вашому сайті</div>
                            <ul>
                                <li>Брендований калькулятор в вашому стилі</li>
                                <li>Технічна допомога та супровід при інтеграції</li>
                                <li>Коротке та готове рішення для початку продажів</li>
                            </ul>
                            <router-link class="btn btn-blue to-form" :to="{ name: 'registration'}">
                                Cпробувати безкоштовно
                            </router-link>
                        </div>
                        <div class="connect-variants__slider__container sl2">
                            <div class="variants__slider swiper-container">
                                <div class="variants__slider__list swiper-wrapper">
                                    <div class="variants__slider__item swiper-slide"><img src="/images/connect/variant-slide6.png" alt="slide"></div>
                                    <div class="variants__slider__item swiper-slide"><img src="/images/connect/variant-slide7.png" alt="slide"></div>
                                    <div class="variants__slider__item swiper-slide"><img src="/images/connect/variant-slide8.png" alt="slide"></div>
                                    <div class="variants__slider__item swiper-slide"><img src="/images/connect/variant-slide9.png" alt="slide"></div>
                                    <div class="variants__slider__item swiper-slide"><img src="/images/connect/variant-slide10.png" alt="slide"></div>
                                </div>
                                <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                            </div>
                            <div class="variants__slider__nav">
                                <button class="variants__slider__btn prev" tabindex="0" role="button" aria-label="Previous slide" aria-disabled="false">
                                    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 20.559L10.6101 22L9.61651e-07 11L10.6101 -9.27563e-07L12 1.441L2.77454 11.0055L12 20.559Z" fill="black"></path>
                                    </svg>
                                </button>
                                <button class="variants__slider__btn next" tabindex="0" role="button" aria-label="Next slide" aria-disabled="false">
                                    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M-1.79732e-06 20.559L1.38992 22L12 11L1.38992 -9.27563e-07L-1.25976e-07 1.441L9.22546 11.0055L-1.79732e-06 20.559Z" fill="black"></path>
                                    </svg>
                                </button>
                            </div>
                            <div class="variants__slider__pagination"></div>
                        </div>
                        </div>
                    </div>
                    <div id="variant3" class="tab-pane fade">
                        <div class="connect-variants__wrapp">
                        <div class="connect-variants__info">
                            <div class="heading">Калькулятор ОСЦПВ на вашому сайті</div>
                            <ul>
                                <li>Брендований калькулятор в вашому стилі</li>
                                <li>Технічна допомога та супровід при інтеграції</li>
                                <li>Коротке та готове рішення для початку продажів</li>
                            </ul>
                            <router-link class="btn btn-blue to-form" :to="{ name: 'registration'}">
                                Cпробувати безкоштовно
                            </router-link>
                        </div>
                        <div class="connect-variants__slider__container sl3">
                            <div class="variants__slider swiper-container">
                                <div class="variants__slider__list swiper-wrapper">
                                    <div class="variants__slider__item swiper-slide"><img src="/images/connect/variant-slide11.png" alt="slide"></div>
                                </div>
                                <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                            </div>
                            <div class="variants__slider__nav">
                                <button class="variants__slider__btn prev" tabindex="0" role="button" aria-label="Previous slide" aria-disabled="false">
                                    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 20.559L10.6101 22L9.61651e-07 11L10.6101 -9.27563e-07L12 1.441L2.77454 11.0055L12 20.559Z" fill="black"></path>
                                    </svg>
                                </button>
                                <button class="variants__slider__btn next" tabindex="0" role="button" aria-label="Next slide" aria-disabled="false">
                                    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M-1.79732e-06 20.559L1.38992 22L12 11L1.38992 -9.27563e-07L-1.25976e-07 1.441L9.22546 11.0055L-1.79732e-06 20.559Z" fill="black"></path>
                                    </svg>
                                </button>
                            </div>
                            <div class="variants__slider__pagination"></div>
                        </div>
                        </div>
                    </div>
                    <div id="variant4" class="tab-pane fade">
                        <div class="connect-variants__wrapp">
                        <div class="connect-variants__info">
                            <div class="heading">Калькулятор ОСЦПВ на вашому сайті</div>
                            <ul>
                                <li>Брендований калькулятор в вашому стилі</li>
                                <li>Технічна допомога та супровід при інтеграції</li>
                                <li>Коротке та готове рішення для початку продажів</li>
                            </ul>
                            <router-link class="btn btn-blue to-form" :to="{ name: 'registration'}">
                                Cпробувати безкоштовно
                            </router-link>
                        </div>
                        <div class="connect-variants__slider__container sl4">
                            <div class="variants__slider swiper-container">
                                <div class="variants__slider__list swiper-wrapper">
                                    <div class="variants__slider__item swiper-slide"><img src="/images/connect/variant-slide12.png" alt="slide"></div>
                                    <div class="variants__slider__item swiper-slide"><img src="/images/connect/variant-slide13.png" alt="slide"></div>
                                    <div class="variants__slider__item swiper-slide"><img src="/images/connect/variant-slide14.png" alt="slide"></div>
                                </div>
                                <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                            </div>
                            <div class="variants__slider__nav">
                                <button class="variants__slider__btn prev" tabindex="0" role="button" aria-label="Previous slide" aria-disabled="false">
                                    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 20.559L10.6101 22L9.61651e-07 11L10.6101 -9.27563e-07L12 1.441L2.77454 11.0055L12 20.559Z" fill="black"></path>
                                    </svg>
                                </button>
                                <button class="variants__slider__btn next" tabindex="0" role="button" aria-label="Next slide" aria-disabled="false">
                                    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M-1.79732e-06 20.559L1.38992 22L12 11L1.38992 -9.27563e-07L-1.25976e-07 1.441L9.22546 11.0055L-1.79732e-06 20.559Z" fill="black"></path>
                                    </svg>
                                </button>
                            </div>
                            <div class="variants__slider__pagination"></div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="connect-partners">
            <div class="container">
                <h2>ukasko.connect - це можливість співпраці з лідерами страхового ринку</h2>
                <div class="descr">Ми вже зробили інтеграцію зі страховими компаніями заздалегідь. Вам залишилося інтегруватися тільки з ukasko.connect</div>

                <a style="font-size: 20px; text-align: center; display: block;" href="https://docs.google.com/document/d/1Ezd_jiFZVHv3qA_gqJmZopvg0tByqUjO/edit" target="_blank"
                   class="order-memo__link link-mob download_General_Rules_ru">
                    Інформація про партнерські страхові компанії
                </a>
                <br>
                <div class="list">
                    <a href="https://ukasko.ua/company/strakhova-kompaniya-ingo-uk-"><img src="https://ukasko.ua/storage/logos/company/logotip-kompanii-strakhova-kompaniya-ingo-uk--1719996446.png" data-src="https://ukasko.ua/storage/logos/company/logotip-kompanii-strakhova-kompaniya-ingo-uk--1719996446.png" title="Страховая компания - Інго" alt="СК - Інго" class="lazy loaded" data-was-processed="true"></a>
                    <a href="https://ukasko.ua/uk/company/pzu-ukraina"><img src="https://ukasko.ua/storage/logos/company/sdfsdgaewfd.png" data-src="https://ukasko.ua/storage/logos/company/sdfsdgaewfd.png" title="Страховая Компания - PZU" alt="Страховая компания &quot;PZU&quot;" class="lazy loaded" data-was-processed="true"></a>
                    <a href="https://ukasko.ua/uk/company/sk-vuso"><img src="https://ukasko.ua/storage/logos/company/dbsiti3mxlp2mpkahdjvrijowbykpbaskxej2tag.png" data-src="https://ukasko.ua/storage/logos/company/dbsiti3mxlp2mpkahdjvrijowbykpbaskxej2tag.png" title="Страховая компания - VUSO" alt="Страховая компания VUSO - Вусо" class="lazy loaded" data-was-processed="true"></a>
                    <a href="https://ukasko.ua/uk/company/sg-tas"><img src="https://ukasko.ua/storage/logos/company/t2kfegaiqcdltlbkdpj4gnbsu4s2qsduqhaduai2.png" data-src="https://ukasko.ua/storage/logos/company/t2kfegaiqcdltlbkdpj4gnbsu4s2qsduqhaduai2.png" title="Страховая компания - СГ ТАС" alt="Страховая Група ТАС" class="lazy loaded" data-was-processed="true"></a>
                    <a href="https://ukasko.ua/uk/company/sk-kredo"><img src="https://ukasko.ua/storage/logos/company/emt4fmmncemlsvqthzfcoeb1hhrzinqexhfunkjw.png" data-src="https://ukasko.ua/storage/logos/company/emt4fmmncemlsvqthzfcoeb1hhrzinqexhfunkjw.png" title="Страховая компания - CREDO" alt="СК - Кредо" class="lazy loaded" data-was-processed="true"></a>
                    <a href="https://ukasko.ua/company/oberig"><img src="https://ukasko.ua/storage/logos/company/towfh2b9a9uajleafblesylugprhidtljhpytd09.png" data-src="https://ukasko.ua/storage/logos/company/towfh2b9a9uajleafblesylugprhidtljhpytd09.png" title="Страховая компания - Обериг" alt="СК Обериг" class="lazy loaded" data-was-processed="true"></a>
                    <a href="https://ukasko.ua/company/sk-evroins-ukraina"><img src="https://ukasko.ua/storage/logos/company/0jkxb3ndk1ophjuze0xofvegnurr6f6gfwvdjbw2.png" data-src="https://ukasko.ua/storage/logos/company/0jkxb3ndk1ophjuze0xofvegnurr6f6gfwvdjbw2.png" title="Страховая компания - Евроинс Украина" alt="Евроинс Украина - Страховая компания" class="lazy loaded" data-was-processed="true"></a>
                    <a href="https://ukasko.ua/company/prat-usk-knyazha-vienna-inshurans-grup-uk"><img src="https://ukasko.ua/storage/logos/company/logotip-kompanii-prat-usk-knyazha-vienna-inshurans-grup-uk-1700475591.png" data-src="https://ukasko.ua/storage/logos/company/logotip-kompanii-prat-usk-knyazha-vienna-inshurans-grup-uk-1700475591.png" title="Страховая компания - КНЯЖА ВІЄННА ІНШУРАНС ГРУП" alt="КНЯЖА ВІЄННА ІНШУРАНС ГРУП - Страховая компания" class="lazy loaded" data-was-processed="true"></a>
                    <a href="https://ukasko.ua/company/usg"><img src="https://ukasko.ua/storage/logos/company/jtwngomj8okgupb1ivnycrykzcesgbjdrvpxikjy.png" data-src="https://ukasko.ua/storage/logos/company/jtwngomj8okgupb1ivnycrykzcesgbjdrvpxikjy.png" title="Страховая компания - УСГ" alt="Украинская страховая группа - Страховая компания" class="lazy loaded" data-was-processed="true"></a>
                    <a href="https://ukasko.ua/company/brokbiznes"><img src="https://ukasko.ua/storage/logos/company/logotip-kompanii-brokbiznes-1731005329.webp" data-src="https://ukasko.ua/storage/logos/company/logotip-kompanii-brokbiznes-1731005329.webp" title="Страховая Компания - БРОКБІЗНЕС" alt="Страховая компания &quot;БРОКБІЗНЕС&quot;" class="lazy loaded" data-was-processed="true"></a>
                    <a href="https://ukasko.ua/company/uniqa"><img src="https://ukasko.ua/storage/logos/company/qljwvu3lbyssoxtxpalmemmytzazmg0kpsacxx4h.png" data-src="https://ukasko.ua/storage/logos/company/qljwvu3lbyssoxtxpalmemmytzazmg0kpsacxx4h.png" title="Страховая компания - UNIQA" alt="Страховая компания UNIQA" class="lazy loaded" data-was-processed="true"></a>
                    <a href="https://ukasko.ua/company/sk-arx"><img src="https://ukasko.ua/storage/logos/company/ev59hlg9bkvqzzcvsac7ysxqyj3kxdojqy81tkti.png" data-src="https://ukasko.ua/storage/logos/company/ev59hlg9bkvqzzcvsac7ysxqyj3kxdojqy81tkti.png" title="Страховая компания - ARX" alt="Страховая компания ARX" class="lazy loaded" data-was-processed="true"></a>
                    <!--  <a href="https://ukasko.ua/company/alfa-garant"><img src="https://ukasko.ua/storage/logos/company/vyta18zizgvbdpvcecf7tvapmkky5hcg6k8fuibw1.png" data-src="https://ukasko.ua/storage/logos/company/vyta18zizgvbdpvcecf7tvapmkky5hcg6k8fuibw1.png" title="Страховая Компания - Альфа-Гарант" alt="Страховая компания &quot;Альфа-Гарант&quot;" class="lazy loaded" data-was-processed="true"></a>-->
                    <!--  <a href="https://ukasko.ua/uk/company/premer-aljans"><img src="https://ukasko.ua/storage/logos/company/uo2few7nuykk5kwjcddrii9zrolegxfwzqxv65kq.png" data-src="https://ukasko.ua/storage/logos/company/UO2fEw7NUyKK5KWjcDDrII9ZrOLeGxFWZQXv65Kq.png" title="Страховая компания - Премер Альянс" alt="СК - Премер Альянс" class="lazy loaded" data-was-processed="true"></a>-->
                    <!--  <a href="https://ukasko.ua/company/ukrainskiy-strakhoviy-standart"><img src="https://ukasko.ua/storage/logos/company/ukrainskij-strahovoj-standart-logo.png" data-src="https://ukasko.ua/storage/logos/company/ukrainskij-strahovoj-standart-logo.png" title="Украинский страховой стандарт - Логотип" alt="Логотип компании Украинский страховой стандарт" class="lazy loaded" data-was-processed="true"></a>-->
                    <!--  <a href="https://ukasko.ua/company/mega-garant"><img src="https://ukasko.ua/storage/logos/company/bgwkga1ybmwa8as0v4oh6yo8krpm0666vtafh10c.png" data-src="https://ukasko.ua/storage/logos/company/bgwkga1ybmwa8as0v4oh6yo8krpm0666vtafh10c.png" title="Страховая компания - Megagarant" alt="МегаГарант - Страховая компания" class="lazy loaded" data-was-processed="true"></a>-->
                </div>
                <a href="https://ukasko.ua/partners" class="btn btn-blue">Показати всіх</a>
            </div>
        </section>

        <section class="connect-benefits">
            <div class="container">
                <h2>Переваги платформи ukasko.connect</h2>
                <div class="connect-benefits__slider__container">
                    <div class="benefits__slider swiper-container">
                        <div class="benefits__slider__list swiper-wrapper">
                        <div class="benefits__slider__item swiper-slide">
                            <div class="logo"><img src="/images/connect/benefit1.svg"></div>
                            <p>Інтеграція з сервісами МВС (можливість підбору та оформлення страхового поліса за номером авто)</p>
                        </div>
                        <div class="benefits__slider__item swiper-slide">
                            <div class="logo"><img src="/images/connect/benefit2.svg"></div>
                            <p>Автоматична перевірка чинності поліса за номером договору або номерним знаком</p>
                        </div>
                        <div class="benefits__slider__item swiper-slide">
                            <div class="logo"><img src="/images/connect/benefit3.png"></div>
                            <p>Всі поліси автоматично потрапляють у додаток "Дiя" та будуть завжди під рукою у клієнта</p>
                        </div>
                        </div>
                        <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                    </div>
                    <div class="benefits__slider__nav">
                        <button class="benefits__slider__btn prev" tabindex="0" role="button" aria-label="Previous slide" aria-disabled="true">
                        <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 20.559L10.6101 22L9.61651e-07 11L10.6101 -9.27563e-07L12 1.441L2.77454 11.0055L12 20.559Z" fill="black"></path>
                        </svg>
                        </button>
                        <button class="benefits__slider__btn next" tabindex="0" role="button" aria-label="Next slide" aria-disabled="true">
                        <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-1.79732e-06 20.559L1.38992 22L12 11L1.38992 -9.27563e-07L-1.25976e-07 1.441L9.22546 11.0055L-1.79732e-06 20.559Z" fill="black"></path>
                        </svg>
                        </button>
                    </div>
                    <div class="benefits__slider__pagination"></div>
                </div>
            </div>
        </section>

        <section class="connect-cooperation">
            <div class="container">
                <h2>Співпраця з ukasko.connect - це</h2>
                <div class="connect-cooperation__wrapp">
                    <div class="content"><img src="/images/connect/cooperation.svg" alt="img"></div>
                    <div class="content-mob">
                        <div class="content-item">Наявність зручного бек-офісу для настройки продуктів, замовлень, точок продажів, перегляду статистики</div>
                        <div class="content-item">Можливість співпраці з кращими страховими компаніями України</div>
                        <div class="content-item">Гнучка система комісійних винагород (при збільшенні обсягів продажів зростає і ваша комісія)</div>
                        <div class="content-item">Можливість використання маркетингових інструментів (знижки, кешбек, промокод)</div>
                        <div class="content-item">Широкий вибір варіантів співпраці - вибирайте кращий варіант для вашої моделі бізнесу</div>
                        <div class="content-item">Наявність інтеграції, що не потребують витрат, і кваліфікована оперативна підтримка клієнтів</div>
                    </div>
                    <router-link class="btn btn-blue to-form" :to="{ name: 'registration'}">
                        Стати партнером
                    </router-link>
                </div>
            </div>
        </section>

        <section class="connect-schedule">
            <div class="container">
                <div class="connect-schedule__content">
                    <h2>Діджіталізація страхового ринку</h2>
                    <div class="descr">Зараз в Україні відбувається різка діджіталізація страхового ринку. Сучасний споживач вимагає сучасних рішень. Тому компанії, які володіють готовим рішенням страхувати свою клієнтську базу онлайн, знаходяться на хвилі розвитку цього сегмента ринку.
                    </div>
                    <div class="params">
                        <div class="heading">Поквартальна динаміка кількості електронних ОСЦПВ і премій (2018-2020 р)</div>
                        <div class="wrapp">
                        <div class="item">
                            <svg width="56" height="16" viewBox="0 0 56 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line y1="7.5" x2="56" y2="7.5" stroke="#009BEB" stroke-dasharray="3 3"></line>
                                <circle cx="28" cy="8" r="7" fill="white" stroke="#009BEB" stroke-width="2"></circle>
                            </svg>
                            <span>Премії, грн</span>
                        </div>
                        <div class="item">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="16" height="16" rx="3" fill="#FFAD3D"></rect>
                            </svg>
                            <span>Кількість е-полісів, шт</span>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="connect-schedule__mob">
                    <select id="schedule-select">
                        <option value="1-2018">1 кв. 2018</option>
                        <option value="2-2018">2 кв. 2018</option>
                        <option value="3-2018">3 кв. 2018</option>
                        <option value="4-2018">4 кв. 2018</option>
                        <option value="1-2019">1 кв. 2019</option>
                        <option value="2-2019">2 кв. 2019</option>
                        <option value="3-2019">3 кв. 2019</option>
                        <option value="4-2019">4 кв. 2019</option>
                        <option value="1-2019">1 кв. 2020</option>
                    </select>
                    <div class="connect-schedule__value">1 713 759</div>
                    <div class="connect-schedule__count">2570</div>
                    <div class="connect-schedule__date">1 кв. 2018</div>
                </div>
                <div class="connect-schedule__chart"><img src="/images/connect/schedule.svg"></div>
            </div>
        </section>

        <section class="connect-partners clients">
            <div class="container">
                <h2>З нами вже працюють</h2>
                <div class="list">
                    <div><img src="/images/connect/clients/client2.png"></div>
                    <div><img src="/images/connect/clients/client3.png"></div>
                    <div><img src="/images/connect/clients/client4.png"></div>
                    <div><img src="/images/connect/clients/client5.png"></div>
                    <div><img src="/images/connect/clients/client6.png"></div>
                    <div><img src="/images/connect/clients/client7.png"></div>
                    <div><img src="/images/connect/clients/client8.png"></div>
                    <div><img src="/images/connect/clients/client10.png"></div>
                    <div><img src="/images/connect/clients/client11.png"></div>
                </div>
                <a href="https://ukasko.ua/partners" class="btn btn-blue">Показати всіх</a>
            </div>
        </section>

        <section class="faq connect-fag">
            <div class="container">
                <h2>Поширені запитання</h2>
                <div class="row">
                    <div class="col-12">
                        <div class="row ldm-list">
                        <div class="col-lg-6">
                            <div class="faq-item">
                                <h4><a href="#faq-1" data-toggle="collapse" aria-controls="faq-1" class="collapsed faq-preview"><span>🥇 Як працює Ukasko.Connect?</span></a></h4>
                                <div id="faq-1" class="collapse faq-text">
                                    <span>
                                    <ul>
                                        <li aria-level="1" style="font-weight: 400;"><span style="font-weight: 400;">Ви заповнюєте параметри автомобіля клієнта та вибираєте відповідний страховий поліс, порівнявши інформацію про страховки та запропоновані компанії;</span></li>
                                        <li aria-level="1" style="font-weight: 400;"><span style="font-weight: 400;">Заповнюєте дані клієнта, та оформляєте поліс;</span></li>
                                        <li aria-level="1" style="font-weight: 400;"><span style="font-weight: 400;">Поліс моментально реєструється в базі МТСБУ та з'являється в особистому кабінеті. Його можна надрукувати, а також він буде відправлений на e-mail клієнта;</span></li>
                                        <li aria-level="1" style="font-weight: 400;"><span style="font-weight: 400;">Ви отримуєте комісійну винагороду за проданий поліс.</span></li>
                                    </ul>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faq-item">
                                <h4><a href="#faq-2" data-toggle="collapse" aria-controls="faq-2" class="collapsed faq-preview"><span>🤝 Як почати роботу?</span></a></h4>
                                <div id="faq-2" class="collapse faq-text">
                                    <span>
                                    <ul>
                                        <li>
                                            <span style="font-weight: 400;">Зв'яжіться з нами за номером телефону <a href="tel:+38%20(063) 29 34 977">+38 (063) 293 49 77</a>, зателефонувавши або за допомогою Viber, Whatsapp чи Telegram, або напишіть на пошту</span>
                                            <ul>
                                                <li><a href="mailto:d.hlushchenko@ukasko.com.ua"><span style="font-weight: 400;">d.hlushchenko@ukasko.com.ua</span></a></li>
                                            </ul>
                                        </li>
                                        <li>Ми зареєструємо Вам особистий кабінет, і проінструктуємо по роботі з ним. Укладаємо договір з ФОП та ТОВ.</li>
                                    </ul>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faq-item">
                                <h4><a href="#faq-3" data-toggle="collapse" aria-controls="faq-3" class="collapsed faq-preview"><span>🥉 Для кого Ukasko.Connect?</span></a></h4>
                                <div id="faq-3" class="collapse faq-text">
                                    <span>
                                    <ul>
                                        <li><span style="font-weight: 400;">Для всіх, хто бажає оформляти договори <a href="https://ukasko.ua/uk/insurance/osago">автострахування ОСЦПВ</a> та інші види страховок. Особливо актуально для страхових агентів, брокерських компаній, кураторів агентських мереж, власників майданчиків техоглядів і тих, хто хоче працювати у сфері страхування онлайн.</span></li>
                                    </ul>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faq-item">
                                <h4><a href="#faq-4" data-toggle="collapse" aria-controls="faq-4" class="collapsed faq-preview"><span>🥈 Як дізнаватися про оновлення?</span></a></h4>
                                <div id="faq-4" class="collapse faq-text"><span>Зареєструйтесь, і ми будемо надсилати Вам актуальну інформацію та повідомляти Вас про всі оновлення на платформі.</span></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faq-item">
                                <h4><a href="#faq-5" data-toggle="collapse" aria-controls="faq-5" class="collapsed faq-preview"><span>🥇 Скільки часу буде потрібно для роботи?</span></a></h4>
                                <div id="faq-5" class="collapse faq-text"><span>Реєстрація на платформі займає 5 хвилин. Оформлення страхового поліса займає до 5 хвилин.</span></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faq-item">
                                <h4><a href="#faq-6" data-toggle="collapse" aria-controls="faq-6" class="collapsed faq-preview"><span>🥇 Скільки коштує використання платформи Ukasko.Connect?</span></a></h4>
                                <div id="faq-6" class="collapse faq-text"><span>Використання платформи абсолютно безкоштовне.</span></div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!--<section class="connect-contacts">
            <div class="container">
                <div class="content">
                    <div class="heading">З питань співпраці</div>
                    <div class="name">Солонецкій Данило Олексійович</div>
                    <div class="descr">Менеджер з розвитку компанії</div>
                    <a href="tel:0503095507" class="phone">
                        <div class="icon">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path d="M10.1675 7.87216C9.27988 7.82544 8.82048 8.48729 8.61025 8.80653C8.43116 9.07127 8.49345 9.43723 8.76597 9.61632C9.0385 9.79541 9.39668 9.73312 9.57576 9.46059C9.83272 9.08684 9.94951 9.02455 10.1052 9.03234C10.6114 9.09463 12.6047 10.5507 12.7993 11.0101C12.8461 11.1425 12.8461 11.2748 12.7916 11.4383C12.5813 12.0613 12.2387 12.4973 11.7871 12.6997C11.3666 12.8944 10.845 12.8788 10.2843 12.6452C8.19757 11.7965 6.37554 10.613 4.87276 9.11799C3.38555 7.61521 2.20201 5.79318 1.35329 3.71421C1.12749 3.15358 1.11191 2.63968 1.29879 2.21143C1.50124 1.7676 1.93728 1.41721 2.56019 1.21476C2.72371 1.16026 2.85607 1.16026 2.98844 1.20698C3.44784 1.40164 4.90391 3.39496 4.95841 3.8933C4.9662 4.05681 4.90391 4.17361 4.53016 4.43056C4.26542 4.60965 4.19534 4.97561 4.37443 5.24035C4.55352 5.50509 4.91948 5.57516 5.18422 5.39608C5.50346 5.17806 6.16531 4.72644 6.11859 3.831C6.06408 2.89663 4.24985 0.428335 3.38555 0.109091C2.99623 -0.0310646 2.59134 -0.0388511 2.17865 0.101305C1.24428 0.412762 0.57465 0.973385 0.232048 1.72088C-0.0949825 2.44502 -0.0794096 3.28595 0.27098 4.15025C1.18199 6.37717 2.45118 8.32377 4.0474 9.94335C4.0474 9.95114 4.05518 9.95114 4.06297 9.95114C5.68255 11.5474 7.62916 12.8165 9.84829 13.7198C10.2921 13.8989 10.7359 13.9923 11.1486 13.9923C11.5457 13.9923 11.9195 13.9144 12.2699 13.7509C13.0174 13.4083 13.578 12.7387 13.8894 11.8043C14.0296 11.3916 14.0296 10.9867 13.8817 10.5974C13.5702 9.73312 11.1019 7.91888 10.1675 7.87216Z" fill="#007BBB"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="14" height="14" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                        </div>
                        +38 (050) 309-55-07
                    </a>
                    <a href="mailto:solonetskiyd@ukasko.com.ua" class="mail">
                        <div class="icon">
                        <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.003 2.33401L8.49616 7.36816L2.99637 2.33401C2.77779 2.13645 2.44639 2.1584 2.24896 2.38523C2.05858 2.61206 2.07974 2.95597 2.29832 3.16084L8.15066 8.51694C8.24937 8.60475 8.37629 8.65597 8.50321 8.65597C8.63013 8.65597 8.75 8.61206 8.85576 8.51694L14.7081 3.16084C14.9267 2.96328 14.9478 2.61206 14.7575 2.38523C14.553 2.15109 14.2216 2.12914 14.003 2.33401Z" fill="#007BBB"></path>
                            <path d="M15.4135 0H1.58648C0.712153 0 0 0.739024 0 1.64634V10.3537C0 11.261 0.712153 12 1.58648 12H15.4135C16.2878 12 17 11.261 17 10.3537V1.64634C17 0.739024 16.2878 0 15.4135 0ZM15.9423 10.3537C15.9423 10.6537 15.7026 10.9024 15.4135 10.9024H1.58648C1.29739 10.9024 1.05765 10.6537 1.05765 10.3537V1.64634C1.05765 1.34634 1.29739 1.09756 1.58648 1.09756H15.4135C15.7026 1.09756 15.9423 1.34634 15.9423 1.64634V10.3537Z" fill="#007BBB"></path>
                        </svg>
                        </div>
                        solonetskiyd@ukasko.com.ua
                    </a>
                </div>
            </div>
            <div class="map-container"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2564.573277186877!2d36.25545681593234!3d50.00060932755593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4127a136dd903e7d%3A0xd37362772f735f26!2zVWthc2tvIC0g0J_Qu9Cw0YLRhNC-0YDQvNCwINC-0L3Qu9Cw0LnQvS3RgdGC0YDQsNGF0L7QstCw0L3QuNGP!5e0!3m2!1sru!2sua!4v1591968127816!5m2!1sru!2sua&amp;language=ru" frameborder="0" allowfullscreen="allowfullscreen" style="border: 0px;"></iframe></div>
        </section>-->

    </div>
</template>


<script>
    export default {
    data: () => ({

    }),
    mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/6.8.0/swiper-bundle.min.js')
      document.head.appendChild(recaptchaScript);

    setTimeout(() => {
        $('.to-form').click(function (e){
            e.preventDefault();
            let href = e.target.attributes.href.value;

            $('html, body').animate({
                scrollTop: $(href).offset().top - $('header').height()
            })
        });
        let variantsSlider = new Swiper('.sl1 .variants__slider', {
            direction: 'horizontal',
            slidesPerView: 1,
            loop: false,
            watchSlidesVisibility: true,
            navigation: {
                nextEl: '.sl1 .variants__slider__btn.next',
                prevEl: '.sl1 .variants__slider__btn.prev',
            },
            pagination: {
                el: '.sl1 .variants__slider__pagination',
                clickable: true,
            },
        });

        let variantsSlider2 = new Swiper('.sl2 .variants__slider', {
            direction: 'horizontal',
            slidesPerView: 1,
            loop: false,
            watchSlidesVisibility: true,
            navigation: {
                nextEl: '.sl2 .variants__slider__btn.next',
                prevEl: '.sl2 .variants__slider__btn.prev',
            },
            pagination: {
                el: '.sl2 .variants__slider__pagination',
                clickable: true,
            },
        });
        let variantsSlider3 = new Swiper('.sl3 .variants__slider', {
            direction: 'horizontal',
            slidesPerView: 1,
            loop: false,
            watchSlidesVisibility: true,
            navigation: {
                nextEl: '.sl3 .variants__slider__btn.next',
                prevEl: '.sl3 .variants__slider__btn.prev',
            },
            pagination: {
                el: '.sl3 .variants__slider__pagination',
                clickable: true,
            },
        });
        let variantsSlider4 = new Swiper('.sl4 .variants__slider', {
            direction: 'horizontal',
            slidesPerView: 1,
            loop: false,
            watchSlidesVisibility: true,
            navigation: {
                nextEl: '.sl4 .variants__slider__btn.next',
                prevEl: '.sl4 .variants__slider__btn.prev',
            },
            pagination: {
                el: '.sl4 .variants__slider__pagination',
                clickable: true,
            },
        });

        $('.connect-variants__nav a').click(function(){
            setTimeout(function(){
                variantsSlider.update();
                variantsSlider2.update();
                variantsSlider3.update();
                variantsSlider4.update();
            }, 300);
        });

        let benefitsSlider = new Swiper('.benefits__slider', {
            direction: 'horizontal',
            slidesPerView: 3,
            spaceBetween: 30,
            loop: false,
            watchSlidesVisibility: true,
            navigation: {
                nextEl: '.benefits__slider__btn.next',
                prevEl: '.benefits__slider__btn.prev',
            },
            pagination: {
                el: '.benefits__slider__pagination',
                clickable: true,
            },
            breakpoints: {
                1200: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 2,
                },
                320: {
                    slidesPerView: 1,
                }
            },
        });

        let whoisSliderOffline = new Swiper('.slider-offline .whoIs__slider', {
            direction: 'horizontal',
            slidesPerView: 4,
            spaceBetween: 30,
            loop: false,
            watchSlidesVisibility: true,
            pagination: {
                el: '.slider-offline .whoIs__slider__pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.slider-offline .whoIs__slider__btn.next',
                prevEl: '.slider-offline .whoIs__slider__btn.prev',
            },
            breakpoints: {
                1200: {
                    slidesPerView: 4,
                },
                768: {
                    slidesPerView: 2,
                },
                320: {
                    slidesPerView: 1,
                }
            },
        });

        let whoisSliderOnline = new Swiper('.slider-online .whoIs__slider', {
            direction: 'horizontal',
            slidesPerView: 4,
            spaceBetween: 30,
            loop: false,
            watchSlidesVisibility: true,
            pagination: {
                el: '.slider-online .whoIs__slider__pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.slider-online .whoIs__slider__btn.next',
                prevEl: '.slider-online .whoIs__slider__btn.prev',
            },
            breakpoints: {
                1200: {
                    slidesPerView: 4,
                },
                768: {
                    slidesPerView: 2,
                },
                320: {
                    slidesPerView: 1,
                }
            },
        });

        $('.connect-whoIs .nav a').click(function(){
            setTimeout(function(){
                whoisSliderOffline.update();
                whoisSliderOnline.update();
            }, 300);
        });

        let scheduleList = [
            {value: '1 713 759', count: '2570', date: '1 кв. 2018', search: '1-2018'},
            {value: '12 344 409', count: '18 721', date: '2 кв. 2018', search: '2-2018'},
            {value: '28 308 129', count: '39 974', date: '3 кв. 2018', search: '3-2018'},
            {value: '63 145 909', count: '85 505', date: '4 кв. 2018', search: '4-2018'},
            {value: '104 938 804', count: '149 182', date: '1 кв. 2019', search: '1-2019'},
            {value: '183 635 611', count: '259 962', date: '2 кв. 2019', search: '2-2019'},
            {value: '257 975 232', count: '345 097', date: '3 кв. 2019', search: '3-2019'},
            {value: '396 641 403', count: '468 096', date: '4 кв. 2019', search: '4-2019'},
            {value: '468 140 063', count: '549 190', date: '1 кв. 2020', search: '1-2020'}
        ]

        $('#schedule-select').on('change', function() {
            let val  = this.value;
            var result = scheduleList.filter(function (elem) {
                return elem.search == val;
            })

            $('.connect-schedule__value').text(result[0].value);
            $('.connect-schedule__count').text(result[0].count);
            $('.connect-schedule__date').text(result[0].date);
        });

    }, 1000);

    },
    methods: {

    }
  }

</script>

